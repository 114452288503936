.card-body .card {
  margin: 0 -1rem;
}

@keyframes float {
  0% {
    filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.2));
    transform: translatey(0px);
  }
  50% {
    filter: drop-shadow(9px 13px 10px rgba(0, 0, 0, 0.6));
    transform: translatey(-20px);
  }
  100% {
    filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.2));
    transform: translatey(0px);
  }
}
.heropenguin {
  transform: translatey(0px);
  filter: drop-shadow(2px 2px 10px rgba(200, 200, 200, 0.2));
  animation: float 6s ease-in-out infinite;
  height: 100px;
  width: 100px;
}
